import React from 'react';
import {Link} from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const keywords = [
  'Troy Carlson',
  'Grand Rapids',
  'Michigan',
  'San Francisco',
  'California',
];

const IndexPage = () => (
  <Layout hideHeader={true}>
    <SEO title="Home" keywords={keywords} />
    <h2>Hi, my name is Troy Carlson.</h2>
    <p>
      I label myself an entrepreneurial hacker and design nerd.
      I <Link to="/projects">build things</Link> and <Link to="blog">write stuff</Link>.
      Check out what I&#39;m up to <Link to="/now/">now</Link> or
      see what <Link to="/books">books I recommend</Link>.
    </p>
    <p>
      You can also find me on&nbsp;
      <a href="https://twitter.com/troy_carlson">Twitter</a>,&nbsp;
      <a href="https://linkedin.com/in/trobeca">LinkedIn</a>,&nbsp;
      <a href="https://github.com/troycarlson">GitHub</a>, and&nbsp;
      <a href="https://stackoverflow.com/users/1302317?tab=profile">Stack Overflow</a>.
    </p>
  </Layout>
);

export default IndexPage;
